export default {
    redirects: {
        path: '/v2/redirects?from=_FROM_',
    },
    general: {
        path: '/v2/master-data',
    },
    extension: {
        path: '/v1/extension/uninstall',
    },
    search: {
        path: '/v1/search',
    },
    scrapper: {
        path: '/v1/images/scrapper',
    },
    useDiscountFeedback: {
        path: '/v2/discounts/_SLUG_/use',
    },
    childrenComments: {
        path: '/v1/comments/children',
    },
    giftsHistory: {
        path: '/v1/user/gifts/_ID_',
    },
    gifts: {
        path: '/v2/gifts',
    },
    redeemGifts: {
        path: '/v1/gifts',
    },
    categories: {
        path: '/v2/categories',
    },
    comments: {
        path: '/v2/comments',
    },
    forumCategories: {
        path: '/v2/forum-categories',
    },
    countries: {
        path: '/v2/countries',
    },
    discounts: {
        path: '/v2/discounts',
    },
    savedDiscounts: {
        path: '/v2/users/_ID_/posts-saved',
    },
    newsletter: {
        subscribe: '/v1/newsletter/subscribe',
        verify: '/v1/newsletter/verify',
        unsubscribe: '/v1/newsletter/unsubscribe',
    },
    coupons: {
        path: '/v2/coupons',
    },
    offers: {
        path: '/v2/offers',
    },
    novelties: {
        path: '/v2/novelties',
    },
    stores: {
        path: '/v2/stores',
        rating: '/v1/stores/score',
    },
    forums: {
        path: '/v2/forums',
    },
    notifications: {
        path: '/v2/notifications',
    },
    notificationsCounter: {
        path: '/v1/notifications/news',
    },
    messagesCounter: {
        path: '/v1/conversations/unread-message-count',
    },
    users: {
        path: '/v2/users',
    },
    articles: {
        path: '/v2/articles',
    },
    lastActivities: {
        path: '/v2/users/_ID_/last-activities',
    },
    coinHistory: {
        path: '/v2/users/_ID_/coins-obtained',
    },
    shortLink: {
        discount: '/v2/discounts/short-link/_ID_',

        store: '/v2/stores/_ID_/short-link/',
    },
}
